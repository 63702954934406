.textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #000;
    color: #fff;
    border: 1px solid #fff;
    margin: auto;
    padding: 10px;
    width: 100% !important;
    text-align: left !important;
    max-width: 960px;
    font-family: Moderat;
    font-weight: 400;
    line-height: 1.1876em;
    font-size: 1rem;
    border-radius: 4px;
}

.upform__questions {
    text-align: left;
    display: block;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 1.43;
}

.form__control {
    color: #fff;
    text-align: left;
    width: 100%;
}

.form__answer__container {
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    justify-content: space-between;
}

.form__answer__input {
    background: transparent !important;
}

.form__answer__input input {
    border: none !important;
    box-shadow: none !important;
    padding: 0.75rem;
    position: relative;
    left: -10px;
}