@font-face {
  font-family: 'Moderat';
  src: url('assets/fonts/Moderat/Webfonts/moderat-regular-webfont.eot');
  src: url('assets/fonts/Moderat/Webfonts/moderat-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Moderat/Webfonts/moderat-regular-webfont.woff2') format('woff2'),
    url('assets/fonts/Moderat/Webfonts/moderat-regular-webfont.woff') format('woff'),
    url('assets/fonts/Moderat/Webfonts/moderat-regular-webfont.ttf') format('truetype'),
    url('assets/fonts/Moderat/Webfonts/moderat-regular-webfont.svg#moderatregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --dark: #1b1c20;
  --gray: #a09e9e;
  --scroll-bg: #313131;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body,
main {
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Moderat';
  height: 100vh;
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.scroll-bar::-webkit-scrollbar {
  width: 5px;
}

.scroll-bar::-webkit-scrollbar-track {
  background-color: var(--scroll-bg);
  border-radius: 100px;
}

.scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 6px solid var(--gray);
  border-left: 0;
  border-right: 0;
  background-color: var(--gray);
}

.up-input {
  color: var(--white);
  width: 100%;
  border: 1px solid var(--white) !important;
  height: 50px;
  padding-left: 10px;
  border-radius: 4px;
  padding-right: 10px;
  background-color: var(--black);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-weight: 400;
  line-height: 1.1876em;
}

.up-input:focus-within,
textarea.up-input:focus {
  border: none !important;
  box-shadow: 0 0 0 0.125rem var(--white) !important;
  outline: none !important;
}

.up-input > input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  border: none !important;
  box-shadow: none !important;
}

textarea.up-input {
  resize: none !important;
}

.up-input > input:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.up-select {
  background: var(--dark) !important;
  height: 3rem !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.up-select:focus {
  border-color: var(--black) !important;
  box-shadow: none !important;
  outline: none !important;
}

.meeting-room-videos div[class*=' ch-remote-video--'] video.ch-video {
  /* transform: scaleX(-1); */
}

.meeting-room-videos :not([class*=' ch-remote-video--']) video.ch-video {
  transform: none !important;
}

.meeting-nav {
  border-right: 1px solid var(--black) !important;
  z-index: 2;
}
.meeting-room-container {
  height: 100%;
  justify-content: center !important;
  overflow: auto;
}
.meeting-room-videos {
  padding-left: 70px; /* sidebar */
}
.meeting-room-contentshare div {
  min-height: 480px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .controls-menu {
    height: 60px !important;
  }

  .meeting-room-container {
    height: 100%;
    justify-content: center;
    overflow: visible;
  }

  .meeting-room-videos {
    padding-left: 0;
    margin-bottom: 110px;
    flex-direction: column !important;
  }

  .meeting-room-videos video {
    max-height: 300px;
  }

  .meeting-room-remote-videos div {
    min-height: 160px;
    max-height: none;
  }

  .meeting-room-local-video div {
    min-height: 160px;
    max-height: none;
  }

  .meeting-room-contentshare div {
    min-height: 200px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .meeting-room-videos {
    flex-direction: column !important;
  }

  .meeting-room-videos video {
    max-height: 480px;
  }

  .meeting-room-remote-videos div {
    min-height: 380px;
    max-height: 480px;
  }

  .meeting-room-local-video div {
    min-height: 380px;
    max-height: 480px;
  }

  .meeting-room-contentshare div {
    min-height: 480px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .meeting-room-videos {
    flex-direction: row !important;
    max-height: none;
  }

  .meeting-room-videos video {
    max-height: none;
  }

  .meeting-room-remote-videos div {
    min-height: 480px;
    max-height: none;
  }

  .meeting-room-local-video div {
    min-height: 480px;
    max-height: none;
  }

  .meeting-room-contentshare div {
    min-height: 480px;
  }
}

@media only screen and (min-width: 992px) and (max-height: 800px) {
  .meeting-room-videos {
    flex-direction: row !important;
    max-height: none;
  }

  .meeting-room-videos video {
    max-height: none;
  }

  .meeting-room-remote-videos div {
    min-height: 280px;
    max-height: none;
  }

  .meeting-room-local-video div {
    min-height: 280px;
    max-height: none;
  }

  .meeting-room-contentshare div {
    min-height: 400px;
  }
}

.up-button {
  border-radius: 50px !important;
  padding: 12px 25px !important;
}

.SingleDatePicker,
.SingleDatePicker .SingleDatePickerInput {
  width: 100%;
}

/* CLEAR-FIX */
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
  margin-bottom: 100px;
}

*:first-child + html .clearfix {
  zoom: 1;
}

/* IE7 */

.align-center {
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
}
